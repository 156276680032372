<template>
  <grid
    type="vendor"
    title="Subcontractors + suppliers"
    :multiple="true"
    :filters="{
      vendor_status: 'a'
    }"
    :filterText="{
      vendor_status: 'Active'
    }"
    :isMain="isMain"
  />
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  name: '',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  mixins: [BodyMixin],
  components: {
    Grid
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>
